import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/tryouts',
  //   name: 'Tryouts',
  //   // route level code-splitting
  //   // this generates a separate chunk (tryouts.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tryouts" */ '../views/Tryouts.vue')
  // },
  // {
  //   path: '/tryout',
  //   name: 'Tryout',
  //   // route level code-splitting
  //   // this generates a separate chunk (tryouts.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tryout" */ '../views/Tryout.vue')
  // },
  // {
  //   path: '/tryouts-registered',
  //   name: 'Tryouts Registered',
  //   // route level code-splitting
  //   // this generates a separate chunk (tryouts-registered.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tryouts-registered" */ '../views/TryoutsRegistered.vue')
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/8v8',
    name: '8v8',
    // route level code-splitting
    // this generates a separate chunk (8v8.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "8v8" */ '../views/8v8.vue')
  },
  {
    path: '/culture',
    name: 'Culture',
    // route level code-splitting
    // this generates a separate chunk (culture.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "culture" */ '../views/Culture.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    // route level code-splitting
    // this generates a separate chunk (faq.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/donate',
    name: 'Donate',
    // route level code-splitting
    // this generates a separate chunk (donate.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "donate" */ '../views/Donate.vue')
  },
  {
    path: '/donation-received',
    name: 'Donation Received',
    // route level code-splitting
    // this generates a separate chunk (donation-received.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "donation-received" */ '../views/DonationReceived.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/training',
    name: 'Training',
    // route level code-splitting
    // this generates a separate chunk (training.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "training" */ '../views/Training.vue')
  },
  {
    path: '/contact-received',
    name: 'Contact Received',
    // route level code-splitting
    // this generates a separate chunk (contact-received.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact-received" */ '../views/ContactReceived.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to, from) => {
//   console.log('yoyo')
// })

export default router
